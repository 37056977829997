import { useState } from 'react'
import { toAbsoluteUrl } from '../AssetHelpers'
import { generateAddress } from '../addressGenerator'
import { configCategoryMap } from '../dataExamples'
import { ViewImageModal } from '../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/ViewImageModal'
import { MapImageModal } from '../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/MapImageModal'
import MapComponent from '../../../app/modules/apps/data-administration/data-admininstration-list/Map'
import FullfillmentModal from '../../../app/modules/apps/inventory/fulfilmentStatus/FullfillmentModal'
import { useAuth } from '../../../app/modules/auth'
import { AuditPopUp } from './AuditPopUp'
import { Story } from './Story'
const configMapData = configCategoryMap



function clipText(text, maxLength = 17, clipper = '...') {


  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
}

const accessNestedProperty = (obj, path) => {
  return path.split('[').reduce((acc, key) => {
    return acc && acc[key.replace(']', '')]
  }, obj)
}

const checkIsTrueOrFalse = (data, name) => {
  return data.includes(name) ? 'Yes' : 'No'
}

const filterImage = (imagesList, name) => {
  return imagesList.filter((x) => x.imageType === name)?.[0]?.imageType || []
}

export const ReturnData = (mapData, user, ref?: any, itemIdForUpdate?: any) => {
  const [showImageModal, setshowImageModal] = useState({
    show: false,
    clicked: '',
    data: []
  })

  const { currentUser }: any = useAuth()
  console.log("currentUser is", currentUser)
  const handleClick = (image) => {
    ref?.current?.open(image, itemIdForUpdate)
  }




  const [showUserTable, setShowUserTable] = useState(false);


  const [showFullfillmentModal, setShowFullfillmentModal] = useState({
    show: false,
    data: null,
  });
  // Handle opening the fulfillment modal
  const handleOpenFullfillmentModal = (fulfillmentData) => {
    setShowFullfillmentModal({
      show: true,
      data: fulfillmentData
    });
  };

  // Handle closing the fulfillment modal
  const handleCloseFullfillmentModal = () => {
    setShowFullfillmentModal({
      show: false,
      data: null
    });
  };


  if (showFullfillmentModal.show) {
    return (
      <FullfillmentModal
        close={handleCloseFullfillmentModal}
        data={user}
      />
    );
  }




  if (showImageModal.show && showImageModal.clicked === "geolocationDispatch") {
    const modifiedData = showImageModal.data
      .map((x: any) => {
        const lat = parseFloat(x.latitude) || 0;
        const lng = parseFloat(x.longitude) || 0;

        // Skip if lat and lng are both 0
        if (lat === 0 && lng === 0) {
          return null;  // You can return null to be filtered out later
        }

        return {
          position: {
            lat,
            lng
          },
          name: ""
        };
      })
      .filter(item => item !== null); // Filters out null entries (invalid coordinates)

    return (
      <MapImageModal
        close={() => setshowImageModal({ show: false, clicked: '', data: [] })}
        data={modifiedData}
        name={showImageModal.clicked}
      />
      // <MapComponent data={modifiedData} />
    );
  }



















  if (showImageModal.show) {
    // console.log({ user })
    return (
      <ViewImageModal
        close={() => setshowImageModal({ show: false, clicked: '', data: [] })}
        images={
          showImageModal.clicked === 'certification_table'
            ? user
            : showImageModal.clicked === 'weightscale_table'
              ? user.ordersInfo
              : showImageModal.clicked === 'vehicleImages'
                ? user?.pickupInfo?.vehicleDetails?.vehicleImages
                : showImageModal.clicked === 'debrisImagePathList'
                  ? user.debrisImagePathList
                  : user.images
        }
        name={showImageModal.clicked}
      />
    )
  }



  function clipText(text, maxLength = 25, clipper = '...') {
    if (!text) return text
    return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
  }

  if (mapData.includes('[')) return accessNestedProperty(user, mapData)
  switch (mapData) {
    case 'status':
      return (
        <span
          className={`badge  badge-light-${user[mapData] === 'MATERIAL_RECEIVED'
            ? 'success'
            : user[mapData] === 'READY_TO_PICKUP'
              ? 'danger'
              : 'primary'
            } fs-7 fw-bold`}
        >
          {user[mapData]}
        </span>
      )
    case 'operationDaysList':
      const configMapDay: any = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
      return (
        <div className='row'>
          {user?.companyDetails?.operatingDays?.map((eachData, idx) => {
            return (
              <div className={`${eachData === 'true' ? 'fw-bold' : ''} col col-lg-1`}>
                {configMapDay[idx]}
              </div>
            )
          })}
        </div>
      )
    case 'SKU':
      return <div>{`${user.name || ''} ${user.gradeId || ''} ${user.colorId || ''}`}</div>
    case 'categoryMap':
      return <div>{configMapData[user.categoryId] || ''}</div>
    case 'format':
    case 'contactDetail':
      return (
        <div>
          <div>{user.personalDetails.mobile}</div>
          <div>{user.personalDetails.email}</div>
        </div>
      )

    case 'ordersInfo':
      return user[mapData]?.map((x: any, ind: number) => (
        <div key={ind + 1 + ''}>{`${x?.customerInfo?.name}`}</div>
      ))

    case 'Unsorted':
    case 'Plastic ':
    case 'Non Plastic':
      const data = user?.orderDetails?.filter((x) => x?.itemName === mapData)?.[0] || {}
      return (
        <div>
          <div>{(data?.weight && data.weight + ' lb') || '-'} </div>
          <div>{data?.quantity || ''}</div>
        </div>
      )
    case 'orderDetailsItemName':
      return (
        <span
          className={`text-${user?.orderDetails?.itemName === 'BOP BLUE' ? 'primary' : 'success'
            } fs-7 fw-bold`}
        >
          {user?.orderDetails?.itemName}
        </span>
      )
    case 'wastage':
      return <span className='text-danger fs-7 fw-bold'>{user.wastage}</span>
    case 'teamInfoSize':
      return <span className='text-primary fs-7 fw-bold'>{user?.teamInfo?.size}</span>
    case 'orderDetailsQuantity':
      return <span className='text-primary fs-7 fw-bold'>{user?.orderDetails?.quantity}</span>
    case 'orderDetailsWeight':
      return <span className='text-primary fs-7 fw-bold'>{user?.orderDetails?.weight}</span>
    case 'orderDetailsDeduction':
      return <span className='text-danger fs-7 fw-bold'>{user?.orderDetails?.weightDeduction}</span>
    case 'netRecivedCalculate':
      return (
        <span className='text-success fs-7 fw-bold'>
          {user?.orderDetails?.weight - user?.orderDetails?.weightDeduction || 0}
        </span>
      )

    case 'teamInfoTeamName':
      return clipText(user?.teamInfo?.teamName, 12, '...')
    case 'customerInfoName':
      return clipText(user?.customerInfo?.name, 15, '...')

    case 'certification_table':
    case 'weightscale_table':
      return (
        <div
          onClick={() =>
            setshowImageModal({
              show: true,
              clicked: mapData,
              data: []
            })
          }
        >
          Click to view
        </div>
      );





    case "remainQuantity":
      return (
        <span
          className="badge fs-7 fw-bold" // Keep the badge class for standard badge styling
          style={{
            backgroundColor: '#ffff99',  // Set the background color to light yellow
            color: '#f6c000'              // Set text color to pure yellow
          }}
        >
          {Math.abs(user.remainQuantity)}
        </span>
      );




    case "fulfilledQuantity":
      return (
        <span
          className="badge badge-light-primary fs-7 fw-bold" // Use the default primary badge style
          style={{
            background: "#e8fff3",
            color: '#50cd89'
          }} // Apply yellow color to text
        >
          {user.fulfilledQuantity}
        </span>
      );



    case 'paymentDetailsAmount':
      return (
        <span className='badge badge-light-info fs-7 fw-bold'>{user?.paymentDetails?.amount}</span>
      )
    case 'pickUpVehicleNumber':
      return (
        <span className='text-success fs-7 fw-bold'>
          {user?.pickupInfo?.vehicleDetails?.registrationNo || ''}
        </span>
      )
    case 'quantityDeposit':
      const totalQuantity =
        user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) || 0
      return totalQuantity
    case 'noOfBags':
      return user?.orderDetails?.quantity || ''
    case 'country':
    case 'city':
    case 'street':
    case 'state':
    case 'landmark':
    case 'zipCode':
    case 'latitute':
    case 'longitute':
      return user?.address?.[mapData] || user?.customerInfo?.address?.[mapData] || ''

    case 'geolocationDispatch':
      // console.log("recent", user.images);
      return (
        <div
          onClick={() =>
            setshowImageModal({
              show: true,
              clicked: "geolocationDispatch",
              data: user.images
            })
          }
        >
          Click to view
        </div>
      )






    case "chainOFCustodyEnv":
      return user?.ordersInfo?.[0]?.dispatchInfo?.street
    case "chainOFCustodyCoun":
      return user?.ordersInfo?.[0]?.customerInfo?.address?.country





    // case 'AuditPopUp': {
    //   return (
    //     <>
    //       <div
    //         className="cursor-pointer text-primary hover:text-primary-dark"
    //         onClick={() => setShowUserTable(true)}
    //         style={{ color: "black" }}
    //       >
    //         Click to view
    //       </div>

    //       <AuditPopUp
    //         show={showUserTable}
    //         user={user}
    //         onClose={() => setShowUserTable(false)}
    //       />
    //     </>
    //   );
    // }


    // case 'AuditPopUp': {
    //   return (
    //     <>
    //       {currentUser.userType !== "OI_ADMIN" ? (
    //         <div
    //           className="cursor-pointer text-primary hover:text-primary-dark"
    //           onClick={() => setShowUserTable(true)}
    //           style={{ color: "black" }}
    //         >
    //           Click to view
    //         </div>
    //       ) : (
    //         <div style={{ color: "#666" }}>-</div>
    //       )}

    //       <AuditPopUp
    //         show={showUserTable}
    //         user={user}
    //         onClose={() => setShowUserTable(false)}
    //       />
    //     </>
    //   );
    // }



    case 'AuditPopUp': {
      return (
        <>
          {currentUser.userType === "AUDITOR" ? (
            user.status === "MATERIAL_INSPECTED" ? (
              <div
                className="cursor-pointer text-primary hover:text-primary-dark"
                onClick={() => setShowUserTable(true)}
                style={{ color: "black" }}
              >
                Click to view
              </div>
            ) : user.status === "APPROVED" ? (
              <div style={{ color: "#28a745", fontWeight: "bold" }}>Audited</div>
            ) : null
          ) : currentUser.userType === "OI_ADMIN" ? (
            user.status === "APPROVED" ? (
              <div style={{ color: "#28a745", fontWeight: "bold" }}>Audited</div>
            ) : (
              <div style={{ color: "#666" }}>-</div>
            )
          ) : (
            <div
              className="cursor-pointer text-primary hover:text-primary-dark"
              onClick={() => setShowUserTable(true)}
              style={{ color: "black" }}
            >
              Click to view
            </div>
          )}

          <AuditPopUp
            show={showUserTable}
            user={user}
            onClose={() => setShowUserTable(false)}
          />
        </>
      );
    }




    // case 'cleanUpStory': {
    //   return (
    //     <>
    //       <div
    //         className="cursor-pointer text-primary hover:text-primary-dark"
    //         onClick={() => setShowUserTable(true)}
    //         style={{ color: "black" }}
    //       >
    //         Click to view
    //       </div>

    //       <Story
    //         show={showUserTable}
    //         user={user}
    //         onClose={() => setShowUserTable(false)}
    //       />
    //     </>
    //   );
    // }
    case 'cleanUpStory': {
      const hasStoryImages = user.images?.some((img: any) => img.imageType === "STORY_STAGE");

      return (
        <>
          <div
            className={`cursor-pointer text-primary hover:text-primary-dark ${!hasStoryImages ? 'opacity-50 pointer-events-none' : ''}`}
            onClick={() => hasStoryImages && setShowUserTable(true)}
            style={{ color: "black" }}
          >
            Click to view
          </div>

          <Story
            show={showUserTable}
            user={user}
            onClose={() => setShowUserTable(false)}
          />
        </>
      );
    }










    case 'startDate':
      const timestamp = user?.start
      const date = new Date(timestamp)
      return new Intl.DateTimeFormat('en-US').format(date)
    case 'endDate':
      const timestamp2 = user?.end
      const date2 = new Date(timestamp2)
      return new Intl.DateTimeFormat('en-US').format(date2)

    // case 'geoLocation':
    //   return (
    //     <div>
    //       <div>
    //         {user?.address?.latitute ||
    //           user?.customerInfo?.address?.latitute ||
    //           user?.dispatchInfo?.latitute ||
    //           ''}
    //       </div>
    //       <div>
    //         {user?.address?.longitute ||
    //           user?.customerInfo?.address?.longitute ||
    //           user?.dispatchInfo?.longitute ||
    //           ''}
    //       </div>
    //     </div>
    //   )


    // case "try":
    //   return (
    //     <div>
    //       {currentUser.userType === "COMPANY" && (
    //         <button onClick={handleOpenFullfillmentModal}>Click to View</button>
    //       )}
    //     </div>
    //   );

    case "Reportc":
      return (
        <div>
          {
            currentUser.userType === "COMPANY" ? (
              <button onClick={handleOpenFullfillmentModal}>Click to View</button>
            ) : (
              <span>-</span>
            )
          }
        </div>
      );






    case 'inMaterials':
    case 'outMaterials':
    case 'productionItemDetails':
      return user[mapData]?.map((x: any, ind: number) => (
        <div key={ind + 1 + ''}>{`${x.name || x.itemName} ${x.gradeId || ''} ${x.colorId || ''
          }`}</div>
      ))
    case 'materialQuantity':
      const materialQuantity = user?.details?.map((x) => x.quantity) || ''
      return materialQuantity.join(', ')
    case 'transportType':
      return (
        <span
          className={`text-${user.transportType === 'OWN' ? 'primary' : 'success'} fs-7 fw-bold`}
        >
          {user.transportType}
        </span>
      )
    case 'outputProductionItem':
      const desData = user?.productionItemDetails.map((x) => `${x.itemName}-${x.quantity}`) || []
      // return desData.join(', ')
      return (
        <div className='m-w-350px' style={{ minWidth: '350px' }}>
          {desData.join(', ')}
        </div>
      )
    case 'hubInfoCity':
      return <div>{user?.hubInfo?.address?.city}</div>
    case 'customAddress':
      const customeAddr = generateAddress(user?.hubInfo?.address, ['city', 'state', 'country'])
      return (
        <div data-bs-toggle='tooltip' title={customeAddr}>
          {clipText(customeAddr, 35)}
        </div>
      )

    case "creationDatee":
      return user?.creationDate

    case 'materialDispatched':
      const materialDispatched = user?.details?.map((x) => x.itemName) || ''
      return <span className='text-primary fs-7 fw-bold'>{materialDispatched.join(', ')}</span>
    case 'plantAddress':
      const addressPlant = generateAddress(user?.hubInfo?.address)
      return (
        <div data-bs-toggle='tooltip' title={addressPlant}>
          {clipText(addressPlant, 25)}
        </div>
      )
    case 'address':
      const addressConcat = generateAddress(
        user?.address ||
        user.pickupInfo?.address ||
        user.dropOffPointInfo?.address ||
        user?.customerInfo?.address ||
        user?.hubInfo?.address
      )
      return (
        <div data-bs-toggle='tooltip' title={addressConcat}>
          {clipText(addressConcat, 25)}
        </div>
      )
    case 'countryCity':
      const cityCount =
        user?.customerInfo?.address.city + ', ' + user?.customerInfo?.address.country
      return cityCount
    case 'materialType':
      return (
        (user.itemId && (user.itemId === '65f1c56169a120743b3ac1ac' ? 'Unsorted' : 'Plastic')) ||
        '-'
      )
    case 'manager':
      return user?.companyDetails?.name || ''
    case 'pickupInfoName':
      return user?.pickupInfo?.name || ''

    case 'creationDate':
      return new Date(user[mapData]).toLocaleDateString()


    case 'completionDate':
      const completionDate = new Date(user[mapData]);
      const today = new Date();

      // Remove the time portion for a correct date comparison
      today.setHours(0, 0, 0, 0); // Set today's time to midnight
      completionDate.setHours(0, 0, 0, 0); // Set the completion date's time to midnight

      const isPastDate = completionDate < today;

      const dateString = completionDate.toLocaleDateString();

      return (
        <span
          className={` `} // No background class, just badge styling for size and weight
          style={{ color: isPastDate ? 'red' : 'inherit' }} // Apply red color if the date is in the past
        >
          {dateString}
        </span>
      );





    case 'paymentAt':
      return user?.paymentDetails?.paymentAt
        ? new Date(+user?.paymentDetails?.paymentAt).toLocaleDateString()
        : '-'
    case 'inspectionCompletedAT':
      return user?.materialInspectionInfo?.inspectionCompletedAT
        ? new Date(+user?.materialInspectionInfo?.inspectionCompletedAT).toLocaleDateString()
        : '-'
    case 'receivingAt':
      return user?.materialRecInfo
        ? new Date(+user?.materialRecInfo?.receivingAt).toLocaleDateString()
        : '-'

    case 'pickupDate':
      // case 'productionId':
      return new Date(
        +user.productionId || +user?.pickupInfo?.pickupDate || new Date()
      ).toLocaleDateString()
    case 'createdAt':
      return new Date(+user?.createdAt || new Date()).toLocaleDateString()
    case 'OI_COLLECTOR':
    case 'MATERIAL_RECEIVING':
    case 'MATERIALINSPECTION':
    case 'PRODUCTION':
    case 'DISPATCH':
    case 'LOGISTICS':
    case 'INVENTORY':
    case 'MY_TRIPS':
      return checkIsTrueOrFalse(user.permissions, mapData)
    case 'selfie':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              onClick={() =>
                handleClick(
                  user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)
                )
              }
              src={user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    /* case 'debrisImagePathList':
      return (
        (user?.debrisImagePathList?.length && (
          <div
            onClick={() =>
              setshowImageModal({
                show: true,
                clicked: mapData,
              })
            }
          >
            <a>click to view</a>
          </div>
        )) ||
        '-'
      ) */
    case 'vehicleImages':
      return (
        <div
          onClick={() =>
            setshowImageModal({
              show: true,
              clicked: mapData,
              data: []
            })
          }
        >
          {user?.pickupInfo?.vehicleDetails?.vehicleImages.length ? 'click to view' : '-'}
        </div>
      )
    case 'IN_PROGRESS_STAGE':
    case 'PRE_STAGE':
    case 'POST_STAGE':
    case 'LOADING':
    case 'SLIP':
      return (
        (user?.images?.length && (
          <div
            onClick={() =>
              setshowImageModal({
                show: true,
                clicked: mapData,
                data: []
              })
            }
          >
            {filterImage(user.images, mapData)?.length ? 'click to view' : '-'}
          </div>
        )) ||
        '-'
      )
    /*   case 'images':
      const teamImage = user[mapData]?.filter((x) => x.imageType === 'TEAM_IMAGE')[0]
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img src={teamImage?.url} alt={user.name} className='w-100' />
          </div>
        </div>
      ) */
    case 'teamPicture':
    case 'avatar':
    /*    case 'weightSlipImagePath':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              onClick={() =>
                handleClick(
                  user[mapData] ||
                    user?.team?.teamImagePath ||
                    user?.kycDocument?.docUrl ||
                    toAbsoluteUrl(`/media/avatars/blank.png`)
                )
              }
              src={
                user[mapData] ||
                user?.team?.teamImagePath ||
                user?.kycDocument?.docUrl ||
                toAbsoluteUrl(`/media/avatars/blank.png`)
              }
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      ) */
    default:
      return typeof user[mapData] === 'boolean'
        ? user[mapData]
          ? 'Yes'
          : 'No'
        : user?.[mapData] || user?.personalDetails?.[mapData] || '-'
  }
}
