/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { ViewImageModal } from '../../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/ViewImageModal'
import { useAuth } from '../../../../app/modules/auth'



type Props = {
  className: string
  data?: any
  name?: string
}

const TablesWidget10: React.FC<Props> = ({ className, name, data = [] }) => {




  const [showImageModal, setshowImageModal] = useState({
    show: false,
    clicked: '',
  })

  const { currentUser }: any = useAuth()

  if (showImageModal.show) {
    return (
      <ViewImageModal
        close={() => setshowImageModal({ show: false, clicked: '' })}
        images={showImageModal.clicked}
      />
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{name ? name : 'Recent Clean-ups'}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
          
            style={{ tableLayout: 'fixed', width: '100%' }}>
            {/* begin::Table head */}


            <thead>
              {currentUser.userType === 'SPONSOR' ? (
                <tr className='fw-bold text-muted'>
                  {/* <th className='w-11'>SN</th>
                  <th className='w-11'>Commitment ID</th>
                  <th className='w-11'>Date</th>
                  <th className='w-11'>Material</th>
                  <th className='w-11'>Quantity</th>
                  <th className='w-11'>Completion Date</th>
                  <th className='w-11'>Status</th> */}

                  <th style={{ width: '12px' }}>SN</th>
                  <th style={{ width: '40px' }}>Commitment ID</th>
                  <th style={{ width: '30px' }}>Date</th>
                  <th style={{ width: '30px' }}>Material</th>
                  <th style={{ width: '30px' }}>Quantity</th>
                  <th style={{ width: '40px' }}>Completion Date</th>
                  <th style={{ width: '60px' }}>Status</th>
                </tr>
              ) : (
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Captain</th>
                  <th className='min-w-150px'>Date</th>
                  <th className='min-w-140px'>Location</th>
                  {/* <th className='min-w-140px'>Environment</th> */}
                  <th className='min-w-120px'>Team Name</th>
                  {/* <th className='min-w-120px'>Delivered</th> */}
                  <th className='min-w-120px'>Country</th>
                  <th className='min-w-100px'>Images</th>
                </tr>
              )}
            </thead>







            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>



              {currentUser.userType === 'SPONSOR' ? (
                Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.creationDate}</td>
                      <td>{new Date(item.creationDate).toLocaleDateString('en-GB')}</td>
                      <td>
                        {item.itemName === 'Plastic '
                          ? 'Ocean Plastic'
                          : item.itemName === 'Unsorted'
                            ? 'Ocean Material'
                            : item.itemName}
                      </td>
                      <td>{item.quantity}</td>
                      <td>{new Date(item.completionDate).toLocaleDateString('en-GB')}</td>
                      <td className='min-w-50px'>
                        <div style={{ position: 'relative', width: '100%' }}>
                          {/* Percentage text */}
                          <div
                            style={{
                              position: 'absolute',
                              left: '0',
                              top: '-18px',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: '#1783fe'
                            }}
                          >
                            75% {/* Change this value to your percentage */}
                          </div>

                          {/* Progress bar container */}
                          <div style={{
                            width: '100%',
                            height: '6px',
                            backgroundColor: '#E5E5E5',
                            borderRadius: '3px',
                            marginTop: '4px'
                          }}>
                            {/* Progress bar fill */}
                            <div
                              style={{
                                width: '75%', // Change this to your percentage
                                height: '100%',
                                backgroundColor: '#1783fe',
                                borderRadius: '3px'
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No data</td>
                  </tr>
                )
              ) : (



                // if admin is logged in 
                <>
                  {data?.length &&
                    data?.slice(0, 10).map((eachData, eachIndex) => {
                      const teamImage = eachData?.images?.filter((x) => x.imageType === 'TEAM_IMAGE')[0]
                      return (
                        <tr key={eachIndex + 1}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img src={teamImage?.url || ''} alt='' />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                  {eachData?.customerInfo?.name}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {eachData?.customerInfo?.address?.country}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {new Date(eachData?.createdAt).toLocaleDateString()}
                            </a>
                            {/*  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Houses &amp; Hotels
                        </span> */}
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-success fw-bold text-hover-primary d-block fs-6'
                            >
                              {eachData?.dispatchInfo?.latitute} , {eachData?.dispatchInfo?.longitute}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {eachData?.dispatchInfo?.street}
                            </span>
                          </td>

                          {/* <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {eachData?.orderDetails?.quantity}
                            </a>
                          </td> */}

                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {eachData?.teamInfo?.teamName}
                            </a>
                          </td>

                          {/*  <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {eachData?.hubInfo?.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {eachData?.hubInfo?.address?.country}
                        </span>
                      </td> */}

                          {currentUser.userType !== 'SPONSOR' && (
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {eachData?.customerInfo?.address?.country}
                              </a>
                            </td>
                          )}

                          <td>
                            <div className='d-flex '>
                              <span
                                className='badge badge-light-primary fs-8 fw-bold'
                                onClick={() =>
                                  setshowImageModal({
                                    show: true,
                                    clicked: eachData.images,
                                  })
                                }
                              >
                                {' '}
                                click to view
                              </span>
                              {/*  <div
                            onClick={() =>
                              setshowImageModal({
                                show: true,
                                clicked: eachData.images,
                              })
                            }
                          >
                            click to view
                          </div> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </>


              )}



            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget10 }
