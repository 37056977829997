import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

interface UserTableModal2Props {
    user: any;
    onClose: () => void;
    show: boolean;
}

export const AuditPopUp: FC<UserTableModal2Props> = ({ user, onClose, show }) => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async () => {
        if (!selectedStatus) return;

        setIsLoading(true);
        setError(null);

        // Map dropdown values to API status values
        const statusMapping: Record<string, string> = {
            'APPROVE': 'APPROVED',
            'REJECT': 'REJECTED',
            'REVERIFICATION': 'HOLD'
        };

        const apiStatus = statusMapping[selectedStatus];

        try {
            // Get auth token from local storage
            const authData = localStorage.getItem('kt-auth-react-v');
            let token = '';

            if (authData) {
                try {
                    const parsedData = JSON.parse(authData);
                    token = parsedData.data?.token || '';
                } catch (parseErr) {
                    console.error('Error parsing auth data:', parseErr);
                    setError('Authentication error. Please log in again.');
                    setIsLoading(false);
                    return;
                }
            }

            if (!token) {
                setError('Authentication token not found. Please log in again.');
                setIsLoading(false);
                return;
            }

            const apiUrl = `https://4ocean-backend-api.asmglobal.co.in/v1/api/trips/${user.tripId}/audit?status=${apiStatus}`;

            const response = await axios.put(apiUrl, null, {
                headers: {
                    'Authorization': token
                }
            });

            console.log('API Response:', response.data);
            onClose(); // Close the modal on success

            // You might want to add a success notification or callback here
        } catch (err) {
            console.error('Error updating status:', err);
            setError('Failed to update status. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            className='modal fade'
            id='kt_modal_view_payment'
            data-backdrop='static'
            tabIndex={-1}
            role='dialog'
            show={show}
            centered
            size="lg"
        >
            <div className="modal-content">
                <div className="modal-header pb-0 border-0 justify-content-end">
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose}>
                        {/* You might want to add an X icon here */}
                    </div>
                </div>

                <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0">
                    <div className="text-center mb-13">
                        <h1 className="mb-3">Update Status</h1>
                    </div>

                    <div className="mb-5">
                        <select
                            className="form-select form-select-solid"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="">Select Status</option>
                            <option value="APPROVE">Approve</option>
                            <option value="REJECT">Reject</option>
                            <option value="REVERIFICATION">Reverification</option>
                            {/* <option value="DISCARD">Discard</option> */}
                        </select>
                    </div>

                    {error && (
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    )}

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={!selectedStatus || isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};