import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../helpers';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import QRCode from 'qrcode.react'
import DownloadPDFButton from '../../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/DownloadPDFButton';

interface OrderItem {
  weight: number;
  orderId: string;
  createdAt: string;
  country: string;
  quantity: number;
  firstImage: string;
}

interface ImageType {
  imageType: string;
  latitude: number | null;
  longitude: number | null;
  url: string;
}

interface TeamInfo {
  teamName: string;
}

interface UserItem {
  images?: ImageType[];
  teamInfo?: TeamInfo;
}

interface OrderDetails {
  data?: UserItem[];
}

interface User {
  data?: {
    orderDetails?: OrderItem[]; // orderDetails is optional and an array of OrderItem
  };
}

type Props = {
  className: string;
  data?: any;
};



const TablesWidget13: React.FC<Props> = ({ className, data = [] }) => {
  const [modalOpen, setModalOpen] = useState(false); // State to track modal visibility
  const [modalData, setModalData] = useState<any>(null); // State to store data for modal
  const [reportModalOpen, setReportModalOpen] = useState(false); // State for the report modal
  const [orderDetails, setOrderDetails] = useState<any>(null); // State to store data for the selected order
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null); // State to store selected orderId

  const { currentUser }: any = useAuth();
  const { pathname } = useLocation();

  const isCommitmentPage = pathname === '/apps/my-commitments';



  // const downloadPdfUrl =
  //   process.env.REACT_APP_BASE_API_URL +
  //   `v1/certificate/download?orderId=${displayData.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'
  //   }`




  // Handler to open the modal and set the data
  const openModal = (item) => {
    setModalData(item);  // Set the selected item data
    setModalOpen(true);   // Open the modal
  };

  // Handler to close the modal
  const closeModal = () => {
    setModalOpen(false);
    setModalData(null); // Clear the modal data
  };

  // Handler to open the report modal and fetch data
  // const openReportModal = (item) => {
  //   setSelectedOrderId(item.orderId); // Set the selected orderId
  //   setReportModalOpen(true); // Open the report modal
  //   // console.log("item is", item)

  //   // Fetch the data for the selected orderId
  //   fetch(`https://4ocean-backend-api.asmglobal.co.in/v1/api/orders/${item.orderDetails[0].orderId}`, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${localStorage.getItem('kt-auth-react-v')}`,
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       setOrderDetails(data);
  //     })
  //     .catch(error => console.error('Error fetching order details:', error));
  // };

  const openReportModal = (item) => {
    setSelectedOrderId(item.orderId); // Set the selected orderId
    setReportModalOpen(true); // Open the report modal
    fetch(`https://4ocean-backend-api.asmglobal.co.in/v1/api/orders/${item.orderDetails[0].orderId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('kt-auth-react-v')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) { // Check if the response status is not OK (e.g., 500, 404, etc.)
          throw new Error(`Error fetching order details: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        setOrderDetails(data);
      })
      .catch(error => {
        console.error(error);
        setOrderDetails(null);
      });
  };




  // console.log("orderDetails is", orderDetails)

  // Handler to close the report modal
  const closeReportModal = () => {
    setReportModalOpen(false);
    setOrderDetails(null); // Clear the order details
  };

  // Modal related styling to disable body scroll when modal is open
  useEffect(() => {
    if (reportModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable body scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable body scroll
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [reportModalOpen]);



  useEffect(() => {
    // Create a new link element
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap";
    link.rel = "stylesheet";

    // Append the link element to the head
    document.head.appendChild(link);

    // Cleanup function to remove the link when the component is unmounted
    return () => {
      document.head.removeChild(link);
    };
  }, []);





  const lastSection = [
    { label: 'Company Name', value: '4Ocean PBC' },
    {
      label: 'Regd Address',
      value: `3600 FAU Blvd Boca Raton, Florida USA 33431`,
    },
    { label: 'Email', value: 'tony.ernst@4ocean.com' },
    { label: 'Website', value: 'https://www.4ocean.com' },
  ]



  return (
    <div className={`card ${className}`}>
      {/* Card Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {currentUser.userType === 'SPONSOR' ? 'Fulfilment Status' : 'Recent Orders'}
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Latest 10 orders</span>
        </h3>
      </div>

      {/* Card Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            style={{ tableLayout: 'fixed', width: '100%' }}
          >
            <thead>
              {currentUser.userType === 'SPONSOR' ? (
                <tr className='fw-bold text-muted'>
                  <th style={{ width: "50px", padding: '', lineHeight: '1.2' }}>SN</th>
                  <th style={{ width: "200px", padding: '14px 10px', lineHeight: '1.2' }}>Commitment ID</th>
                  <th style={{ width: "135px", padding: '14px 10px', lineHeight: '1.2' }}>Date</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>Material</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>Total</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>Completed</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>Balance</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>Completion Date</th>
                  <th className='min-w-120px' style={{ padding: '14px 10px', lineHeight: '1.2' }}>View</th>
                </tr>
              ) : (
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>Order Id</th>
                  <th className='min-w-120px'>{isCommitmentPage ? 'Country' : 'Source'}</th>
                  <th className='min-w-120px'>Date</th>
                  <th className='min-w-120px'>{isCommitmentPage ? 'Commitment' : 'Company'}</th>
                  <th className='min-w-120px'>{isCommitmentPage ? 'Fulfilled' : 'Transport'}</th>
                  <th className='min-w-120px'>Material</th>
                </tr>
              )}
            </thead>

            {currentUser?.userType === 'SPONSOR' && (
              <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{item?.creationDate}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{new Date(item.creationDate).toLocaleDateString('en-GB')}</td>
                      {/* <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{item?.itemName}</td> */}
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{item.itemName === 'Plastic '
                        ? 'Ocean Plastic'
                        : item.itemName === 'Unsorted'
                          ? 'Ocean Material'
                          : item.itemName}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{item?.quantity}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2', color: "#28a75d" }}>{item?.fulfilledQuantity}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{item?.remainQuantity}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>{new Date(item.completionDate).toLocaleDateString('en-GB')}</td>
                      <td style={{ padding: '1px 10px', lineHeight: '0.2' }}>
                        <button onClick={() => openModal(item)} className="btn btn-link">
                          Click to view
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No data</td>
                  </tr>
                )}
              </tbody>
            )}

            {currentUser?.userType !== 'SPONSOR' && data?.length ? (

              <tbody>
                {data?.slice(0, 10).map((eachData, eachIndex) => (
                  <tr key={eachIndex + 1 + ''}>
                    <td>{eachData.createdAt}</td>
                    <td>{eachData?.hubInfo?.address?.country}</td>
                    <td>{new Date(eachData?.createdAt || new Date()).toLocaleDateString()}</td>
                    <td>{eachData?.customerInfo?.name}</td>
                    <td>{eachData.transportType}</td>
                    <td>
                      {eachData?.details?.map((x, len) => (
                        <span className='text-success fw-bold text-hover-primary d-block fs-6'>
                          {x.itemName} {eachData.details.length - 1 === len ? '' : ', '}
                        </span>
                      )) || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>

            ) : (
              <tbody>
                <tr>
                  <td colSpan={6}></td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* Modal to show item details */}
      {modalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              width: '80%',
              maxWidth: '800px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h2>Fulfilment Details</h2>

            <table cellPadding="10" style={{ width: '100%', marginBottom: '20px' }}>
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Impact ID</th>
                  <th>Clean up ID</th>
                  <th>View report</th>
                </tr>
              </thead>
              <tbody>

                {/* {modalData?.tripDetails?.map((item, index) => (
                  <tr key={`${modalData.orderId}-${index}`}>
                    <td>{index + 1}</td>
                    <td>{item.tripId}</td>
                    <td>{item.orderDetails?.[0]?.orderId || 'N/A'}</td>
                    <td>
                      <button onClick={() => openReportModal(item)}>
                        Click to view
                      </button>
                    </td>
                  </tr>
                ))} */}



                {modalData?.tripDetails?.length > 0 ? (
                  modalData.tripDetails.map((item, index) => (
                    <tr key={`${modalData.orderId}-${index}`}>
                      <td>{index + 1}</td>
                      <td>{item.tripId}</td>
                      <td>{item.orderDetails?.[0]?.orderId || 'N/A'}</td>
                      <td>
                        <button onClick={() => openReportModal(item)}>
                          Click to view
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (


                  <tr style={{ position: 'relative' }}>
                    <td
                      style={{
                        marginTop: "10px",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                      }}
                    >
                      No records found
                    </td>
                  </tr>


                )}

              </tbody>
            </table>

            <button
              onClick={closeModal}
              style={{
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}





      {reportModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              width: '80%',
              maxWidth: '800px',
              maxHeight: '98vh', // Modal shouldn't take more than 90% of the viewport height
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              overflowY: 'auto', // Enable vertical scrolling if content overflows
              display: 'flex',
              flexDirection: 'column',
              position: "relative"
            }}
          >


            <div className='col-13' style={{ marginTop: "-25px", display: "flex", justifyContent: "end" }}>
              <button
                onClick={closeReportModal}
                style={{
                  // position: "absolute",
                  padding: '5px 10px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: "50px",
                  marginTop: "20px",
                }}
              >
                X
              </button>
            </div>

            {/* 
            <h2>Report Details</h2>
            <div>
              <p><strong>Order ID:</strong> {orderDetails?.data[0]?.id}</p>
            </div> */}




            <div className='col-13' style={{ marginTop: '15px', display: "flex", justifyContent: "end" }}>
              <img
                // width={100}
                width={'25%'}
                height={'50px'}
                style={{ backgroundColor: '#FFFFFF', marginLeft: '9px', fontFamily: 'GT Walsheim Pro', }}
                src={toAbsoluteUrl('/media/logos/real.jpeg')}
                alt='main-logo'
              />
            </div>








            <div
              style={{
                color: '#0057B8',
                fontSize: '40px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat, sans-serif',
                lineHeight: '54px',
                textAlign: 'center',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: 'url(/media/cleanUpImages/bg.PNG)', // Background for "Clean-up Report"
                backgroundSize: 'cover', // Ensures the background image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the background from repeating
                // minHeight: '300px', // Ensure the div has enough height for the background image
                padding: '35px', // Padding to add space inside the div
                height: "300px"
              }}
              className='text-center'
            >

              Clean-up Report

            </div>





            {/* here goes first line  */}
            <div style={{ display: "flex" }}>
              <div className='col-9'>
                <div className='row'>
                  <div className='col-11'>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px', width: '100%', fontFamily: 'Montserrat, sans-serif', }}>
                      <img
                        src="/media/cleanUpImages/weight.PNG"
                        alt="Location"
                        style={{ height: '50%' }}
                      />
                      <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                        <p
                          style={{ fontSize: "30px", margin: "0", color: "#0457b8", fontWeight: "bold" }}
                        >

                          {
                            orderDetails?.data[0]?.orderDetails?.map(order =>
                              // Sum up the weight of each order item (order.weight)
                              order?.weight || 0
                            ).reduce((totalWeight, orderWeight) => totalWeight + orderWeight, 0)
                          }




                        </p>

                        <span style={{ fontSize: "30px", color: "#0457b8", fontWeight: "bold" }}>Pounds pulled</span>
                      </div>
                    </div>


                  </div>
                </div>
              </div>


              {/* <div className="col-3 d-flex justify-content-center align-items-center">
                {orderDetails?.data?.map((order) => (
                  <QRCode
                    key={order.id} // Use a unique key for each QR code (e.g., order.id)
                    value={
                      process.env.REACT_APP_BASE_API_URL +
                      `v1/certificate/download?orderId=${orderDetails?.data[0]?.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'}`
                    }
                  />
                ))}
              </div> */}






              <div className="col-3 d-flex justify-content-center align-items-center">
                {orderDetails?.data?.map((order) => {
                  const value = process.env.REACT_APP_BASE_API_URL +
                    `v1/certificate/download?orderId=${orderDetails?.data[0]?.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'}`;

                  // Log the value to the console
                  // console.log(value);

                  return (
                    <QRCode
                      key={order.id} // Use a unique key for each QR code (e.g., order.id)
                      value={value}
                    />
                  );
                })}
              </div>







            </div>

            {/* here goes 2nd line */}


            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0', width: '100%', fontFamily: 'Montserrat, sans-serif', }}>
              {/* Date Section */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px', width: '30%' }}>
                <img
                  src="/media/cleanUpImages/date.PNG"
                  alt="Date"
                  style={{ width: '70px', height: 'auto' }}
                />
                <div style={{ fontSize: '20px', margin: '0 0 0 5px', color: '#0457b8', fontWeight: 'bold' }}>
                  {orderDetails?.data?.map((order, index) => (
                    <div key={index}>
                      {new Date(order?.collectionDate || new Date()).toLocaleDateString() || ''}
                    </div>
                  ))}
                </div>
              </div>
              {/* Location Section */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px', width: '30%' }}>
                <img
                  src="/media/cleanUpImages/location.PNG"
                  alt="Location"
                  style={{ width: '70px', height: 'auto' }}
                />
                <div style={{ fontSize: '20px', margin: '0 0 0 5px', color: '#0457b8', fontWeight: 'bold' }}>
                  {orderDetails?.data?.map((order, index) => (
                    <div key={index}>
                      {order?.dispatchInfo?.street || 'No street information'}
                    </div>
                  ))}
                </div>
              </div>


              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px', width: '30%' }}>
                <img
                  src="/media/cleanUpImages/river.PNG"
                  alt="River"
                  style={{ width: '70px', height: 'auto' }}
                />
                <div style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>

                  {orderDetails?.data?.map((order, index) => (
                    <div key={index}>
                      {(order?.dispatchInfo?.latitute
                        ? order?.dispatchInfo?.latitute.toFixed(6).slice(0, 12) // Limits to 6 decimals, 12 characters
                        : '') +
                        ', ' +
                        (order?.dispatchInfo?.longitute
                          ? order?.dispatchInfo?.longitute.toFixed(6).slice(0, 12) // Limits to 6 decimals, 12 characters
                          : '')}
                    </div>
                  ))}
                </div>
              </div>

            </div>


            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
              {/* Wrapper div for side-by-side content */}
              <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%', alignItems: 'center' }}>

                {/* Mapping over user?.data */}
                {orderDetails?.data?.map((userItem, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>

                    {/* Crew Employed Section */}
                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                      <img
                        src="/media/cleanUpImages/people.PNG"
                        alt="Crew Employed"
                        style={{ height: '50px', width: 'auto' }} // Set a fixed height for the image
                      />
                      <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                        <p style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>
                          {userItem?.teamInfo?.size ?? '-'} {/* Handling null or undefined */}
                        </p>
                        <span style={{ fontSize: '20px', color: '#0457b8', fontWeight: 'bold' }}>Crew Employed</span>
                      </div>
                    </div>

                    {/* Captain Section */}
                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                      <img
                        src="/media/cleanUpImages/lo.PNG"
                        alt="Captain"
                        style={{ height: '50px', width: 'auto' }} // Set a fixed height for the image
                      />
                      <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                        <p style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>
                          {userItem?.customerInfo?.name ?? 'N/A'} {/* Fallback to 'N/A' if name is unavailable */}
                        </p>
                        <span style={{ fontSize: '20px', color: '#0457b8', fontWeight: 'bold' }}>Captain</span>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </div>




            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              // border: "2px solid red",
              marginLeft: "50px"
            }}>




              {orderDetails?.data?.length > 0 && orderDetails?.data?.map((userItem: UserItem) => {
                const groupedImages: Record<string, ImageType[]> = {};
                userItem?.images?.forEach((image: ImageType) => {
                  if (!groupedImages[image.imageType]) {
                    groupedImages[image.imageType] = [];
                  }
                  groupedImages[image.imageType].push(image);
                });

                // Map imageType to custom labels
                const imageTypeLabels: Record<string, string> = {
                  'PRE_STAGE': 'Before Clean Up',
                  'IN_PROGRESS_STAGE': 'During Clean Up',
                  'POST_STAGE': 'After Clean Up',
                  'TNC_SIGNATURE': 'Signature',
                  'LOADING': 'Loding',
                  'SCALE_PIC': 'Scale Pic',
                  'SLIP': 'Slip',
                  'TEAM_IMAGE': userItem.teamInfo?.teamName || 'Team Name'
                };

                return Object.entries(groupedImages).map(([imageType, images]: [string, ImageType[]]) => (
                  <div key={imageType} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flexStart',
                    width: '700px' // Width of two 280px items
                  }}>
                    <div style={{
                      display: 'flex',
                      marginBottom: '0'
                    }}>
                      <div style={{
                        width: '300px',
                        height: '300px',
                        backgroundColor: 'rgb(4, 85, 185)',
                        color: "white",
                        fontWeight: "bold",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        padding: '8px',
                        fontSize: '18px',
                        textAlign: 'center',
                        fontFamily: "'Montserrat', sans-serif"
                        // fontWeight: '500'
                      }}>
                        {imageTypeLabels[imageType] || imageType}
                      </div>
                      {images[0] && (
                        <div style={{
                          width: '300px',
                          height: '300px',
                          backgroundImage: `url(${images[0].url})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '4px',
                          boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                        }} />
                      )}
                    </div>

                    {images.slice(1).reduce((rows: any[], image: ImageType, index: number) => {
                      if (index % 2 === 0) {
                        rows.push(
                          <div key={index} style={{
                            display: 'flex',
                            marginBottom: '0'
                          }}>
                            <div style={{
                              width: '300px',
                              height: '300px',
                              backgroundImage: `url(${image.url})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              borderRadius: '4px',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                            }} />
                            {images[index + 2] && (
                              <div style={{
                                width: '300px',
                                height: '300px',
                                backgroundImage: `url(${images[index + 2].url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '4px',
                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                              }} />
                            )}
                          </div>
                        );
                      }
                      return rows;
                    }, [])}
                  </div>
                ));
              })}
            </div>




            <div
              style={{
                marginTop: '20px',
                marginBottom: '-7px',
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'IBM Plex Mono, monospace'
              }}
            >
              Pulled Material Details
            </div>



            <div>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  margin: '20px 0',
                  fontFamily: 'IBM Plex Mono, monospace'
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      SL
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      Item
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      Weight
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      UOM
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        backgroundColor: '#f2f2f2',
                        textAlign: 'left',
                        fontFamily: 'IBM Plex Mono, monospace'
                      }}
                    >
                      UOM
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.data?.map((order, index) => (
                    <React.Fragment key={index}>
                      {/* Iterate over the nested orderDetails for each order */}
                      {order.orderDetails?.map((detail, detailIndex) => (
                        <tr key={detailIndex}>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detailIndex + 1} {/* Combined SL */}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.categoryName}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.itemName}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.weight}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.wightUnit}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.quantity}
                          </td>
                          <td
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              fontFamily: 'IBM Plex Mono, monospace'
                            }}
                          >
                            {detail.unit}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>





            <div
              style={{
                marginTop: '20px',
                marginBottom: '7px',
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'IBM Plex Mono, monospace'
              }}
            >
              Processing Facility
            </div>






            <div>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  margin: '20px 0',
                  fontFamily: 'IBM Plex Mono, monospace',
                }}
              >
                <tbody>
                  {orderDetails?.data?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          fontWeight: 'bold',
                          textAlign: 'left',
                          width: '150px',
                        }}
                      >
                        Name:
                      </td>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          textAlign: 'left',
                        }}
                      >
                        {order?.hubInfo?.name || 'N/A'}
                      </td>
                    </tr>
                  ))}
                  {orderDetails?.data?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          fontWeight: 'bold',
                          textAlign: 'left',
                          width: '150px',
                        }}
                      >
                        Street:
                      </td>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          textAlign: 'left',
                        }}
                      >
                        {order?.hubInfo?.address?.street || 'N/A'}
                      </td>
                    </tr>
                  ))}
                  {orderDetails?.data?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          fontWeight: 'bold',
                          textAlign: 'left',
                          width: '150px',
                        }}
                      >
                        City:
                      </td>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          textAlign: 'left',
                        }}
                      >
                        {order?.hubInfo?.address?.city || 'N/A'}
                      </td>
                    </tr>
                  ))}
                  {orderDetails?.data?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          fontWeight: 'bold',
                          textAlign: 'left',
                          width: '150px',
                        }}
                      >
                        Country:
                      </td>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          textAlign: 'left',
                        }}
                      >
                        {order?.hubInfo?.address?.country || 'N/A'}
                      </td>
                    </tr>
                  ))}
                  {orderDetails?.data?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          fontWeight: 'bold',
                          textAlign: 'left',
                          width: '150px',
                        }}
                      >
                        Zip Code:
                      </td>
                      <td
                        style={{
                          padding: '4px', // Reduced padding for less row height
                          textAlign: 'left',
                        }}
                      >
                        {order?.hubInfo?.address?.zipCode || 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>


            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-11' style={{ background: "#f8f8f4" }}>
                    {lastSection?.map((eachData, ind) => (
                      <div
                        className='row'
                        key={ind + 1 + ''}
                        style={{
                          fontSize: '14px',
                          fontFamily: 'IBM Plex Mono, monospace'
                        }}
                      >
                        <label className='col-lg-3' style={{ color: '#242727', fontWeight: '700' }}>
                          {eachData.label}
                        </label>
                        <div className='col-lg-8' style={{ color: '#4F5152' }}>
                          <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='text-center'>
              <div style={{ fontFamily: 'IBM Plex Mono, monospace', fontSize: "10px", margin: "10px 0" }}>
                This is not a tax invoice. This is computer generated document, no signature is
                required
              </div>
              <div></div>
            </div>


            <div
              // className='text-center'
              style={{
                background: 'white',
                width: 'fit-content',
                margin: '0px auto -22px',
              }}
            >
              <div>
                <img
                  width={100}
                  height={25}
                  src='/media/svg/dashboard/asm-final-2.png'
                  alt='image'
                />
              </div>
            </div>



            <div className='col-13' style={{ display: "flex", justifyContent: "end" }}>
              <DownloadPDFButton
                url={`${process.env.REACT_APP_BASE_API_URL}v1/certificate/download?orderId=${orderDetails?.data[0]?.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'}`}
              />
            </div>
          </div>
        </div>
      )}



    </div >
  );
};

export { TablesWidget13 };
