// /* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
// import { useIntl } from 'react-intl'
// import { KTIcon } from '../../../../helpers'
// import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
// import { SidebarMenuItem } from './SidebarMenuItem'
// import { useAuth } from '../../../../../app/modules/auth'

// const SidebarMenuMain = () => {
//   const intl = useIntl()
//   const { auth } = useAuth()
//   const hideSideBar = auth?.data?.userType === 'SPONSOR' || false
//   const isAuditor = auth?.data?.userType === 'AUDITOR' || false
//   return (
//     <>
//       {!hideSideBar && (
//         <>

//           <SidebarMenuItem
//             to='/dashboard'
//             icon='element-11'
//             title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
//             fontIcon='bi-app-indicator'
//           />
//           <div className='menu-item'>
//             <div className='menu-content pt-8 pb-2'>
//               <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
//             </div>
//           </div>

//           <SidebarMenuItem
//             to='/apps/data-administrations'
//             icon='abstract-28'
//             title='Data Administration'
//             fontIcon='bi-layers'
//           />
//           <SidebarMenuItem
//             to='/apps/operations-management'
//             icon='element-7'
//             title='Operations Management'
//             fontIcon='bi-layers'
//           />
//           {/* <SidebarMenuItem
//             to='/apps/raw-material-inventory'
//             icon='element-9'
//             title='Raw Material Inventory'
//             fontIcon='bi-layers'
//           />
//           <SidebarMenuItem
//             to='/apps/finished-goods-material'
//             icon='element-11'
//             title='Finished Goods Material'
//             fontIcon='bi-layers'
//           /> */}
//           <SidebarMenuItem
//             to='/apps/create-commitment'
//             icon='element-11'
//             title='Create Commitment'
//             fontIcon='bi-layers'
//           />
//           <SidebarMenuItem
//             to='/apps/fulfilment-status'
//             icon='element-11'
//             title='Fulfilment Status'
//             fontIcon='bi-layers'
//           />
//         </>
//       )}
//       {hideSideBar && (
//         <SidebarMenuItem
//           to='/apps/my-commitments'
//           icon='element-11'
//           title='My Commitments'
//           fontIcon='bi-layers'
//         />
//       )}
//     </>
//   )
// }

// export { SidebarMenuMain }








/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const hideSideBar = auth?.data?.userType === 'SPONSOR' || false
  const isAuditor = auth?.data?.userType === 'AUDITOR' || false
  return (
    <>
      {!hideSideBar && (
        <>
          {!isAuditor && (
            <SidebarMenuItem
              to='/dashboard'
              icon='element-11'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'
            />
          )}
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
            </div>
          </div>
          {!isAuditor && (
            <SidebarMenuItem
              to='/apps/data-administrations'
              icon='abstract-28'
              title='Data Administration'
              fontIcon='bi-layers'
            />
          )}
          <SidebarMenuItem
            to='/apps/operations-management'
            icon='element-7'
            title='Operations Management'
            fontIcon='bi-layers'
          />
          {/* <SidebarMenuItem
            to='/apps/raw-material-inventory'
            icon='element-9'
            title='Raw Material Inventory'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/finished-goods-material'
            icon='element-11'
            title='Finished Goods Material'
            fontIcon='bi-layers'
          /> */}
          {!isAuditor && (
            <SidebarMenuItem
              to='/apps/create-commitment'
              icon='element-11'
              title='Create Commitment'
              fontIcon='bi-layers'
            />
          )}
          {!isAuditor && (
            <SidebarMenuItem
              to='/apps/fulfilment-status'
              icon='element-11'
              title='Fulfilment Status'
              fontIcon='bi-layers'
            />
          )}
        </>
      )}
      {hideSideBar && (
        <SidebarMenuItem
          to='/apps/my-commitments'
          icon='element-11'
          title='My Commitments'
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}

export { SidebarMenuMain }
