
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

interface UserTableModal2Props {
    user: any;
    onClose: () => void;
    show: boolean;
}

export const Story: FC<UserTableModal2Props> = ({ user, onClose, show }) => {
    const storyImages = user.images?.filter((img: any) => img.imageType === "STORY_STAGE");

    return (
        <Modal
            className='modal fade'
            id='kt_modal_view_payment'
            data-backdrop='static'
            tabIndex={-1}
            role='dialog'
            show={show}
            centered
            size="lg"
        >
            <div className="modal-content">
                <div className="modal-header pb-0 border-0 justify-content-end">
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose}>
                    </div>
                </div>

                <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0">
                    <div className="text-center mb-13">
                        <h1 className="mb-3">Story Stage</h1>
                    </div>

                    <div className="d-flex flex-wrap gap-4 justify-content-center mb-5">
                        {storyImages?.map((image: any, index: number) => (
                            <div key={index} className="text-center">
                                <img
                                    src={image.url}
                                    alt={`Story ${index + 1} `}
                                    className="img-fluid rounded"
                                    style={{ maxWidth: '300px', height: 'auto' }}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
