// @ts-nocheck
import { Column } from 'react-table'
import { UserInfoCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserInfoCell'
import { UserLastLoginCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserLastLoginCell'
import { UserTwoStepsCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserTwoStepsCell'
import { UserActionsCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserActionsCell'
import { UserSelectionCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserSelectionCell'
import { UserCustomHeader } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserCustomHeader'
import { UserSelectionHeader } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserSelectionHeader'
import { boolean } from 'yup'
import { useLocation } from 'react-router'

import { UserPopularCell } from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserPopularCell'
const generator = (data) => {
  return data.map((eachHeader, idx) => {
    if (eachHeader.label === 'certificate_view') {
      return {
        Header: (props) => (
          <UserCustomHeader tableProps={props} title={'Certificate'} className=' min-w-100px' />
        ),
        id: idx + 1 + '',
        Cell: ({ ...props }) => {
          return props?.data[props.row.index]?.status === 'Completed' ? (
            <UserActionsCell id={props.data[props.row.index].id} action={['view']} />
          ) : null
        },
      }
    } else if (eachHeader?.action?.length > 0) {
      return {
        Header: (props) => {
          return (
            <UserCustomHeader
              tableProps={props}
              title={
                eachHeader.action.includes('certificate')
                  ? 'Certificate'
                  : window.location.pathname == '/apps/production-record'
                    ? 'DPP'
                    : 'Actions'
              }
              className=' min-w-100px'
            />
          )
        },
        id: idx + 1 + '',
        Cell: ({ ...props }) => {
          const rowData = props.data[props.row.index];
          const currentPath = window.location.pathname;

          // Show edit only for chain-of-custody path with END status


          // if (currentPath === '/apps/operations-management/chain-of-custody' && eachHeader.action.includes('edit')) {
          //   return (
          //     <UserActionsCell
          //       allData={rowData}
          //       id={eachHeader?.action.includes('id') ? rowData.id : rowData}
          //       action={rowData.status === 'MATERIAL_INSPECTED' ? ['edit'] : []}
          //     />
          //   )
          // }


          if (currentPath === '/apps/operations-management/chain-of-custody' && eachHeader.action.includes('edit')) {
            return (
              <UserActionsCell
                allData={rowData}
                id={eachHeader?.action.includes('id') ? rowData.id : rowData}
                action={rowData.status === 'APPROVED' ? ['edit'] : []}
              />
            )
          }

          return (
            <UserActionsCell
              allData={rowData}
              id={eachHeader?.action.includes('id') ? rowData.id : rowData}
              action={eachHeader?.action}
            />
          )
        },
      }
    } else
      return {
        Header: (props) => (
          <UserCustomHeader
            tableProps={props}
            title={eachHeader.label}
            className={
              ['S.N', 'ID'].includes(eachHeader.label)
                ? 'min-w-25px'
                : eachHeader.label === 'Address'
                  ? 'min-w-250px'
                  : eachHeader.label === 'Crew'
                    ? 'min-w-35px'
                    : eachHeader.label === 'Captain'
                      ? 'min-w-90px'
                      : eachHeader.label === 'Date'
                        ? 'min-w-30px'
                        : eachHeader.label === 'Country'
                          ? 'min-w-30px'
                          : [
                            'Material Dispatched',
                            'Customer Address',
                            'Received Weight (kg)',
                            'Output Material',
                            'Franchisee',
                            'Name',
                            'Recycler',
                            'Customer',
                            'Facility Name',
                            'INCOTERMS',
                          ].includes(eachHeader.label)
                            ? 'min-w-200px'
                            : 'min-w-125px'
            }
          />
        ),
        id: idx + 1 + '',
        Cell: ({ ...props }) => {
          return ['S.N', 'ID'].includes(eachHeader.label) ? (
            props.row.index + 1
          ) : (
            <UserInfoCell
              mapData={eachHeader.value}
              user={props.data[props.row.index]}
              showImageOnly={['avatar', 'kycDocument', 'images'].includes(eachHeader.value)}
            />
          )
        },
      }
  })
}

const columnGenerators = (header) => {
  return generator(header)
}

export { columnGenerators }